/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { Paper, Fade, ClickAwayListener, useTheme, IconButton, Tooltip, Stack, Avatar } from "@mui/material";
import ActionsBar from "../todo/Actions";
import LabelsBar from "../todo/Labels";
import ContentTitle from "../todo/ContentTitle";
import Content from "../todo/Content";
import { useUiStore, useTodosStore, useUserStore } from "../../store";
import { client } from "../../client";
import { BsPin } from "react-icons/bs";
import { BsPinFill } from "react-icons/bs";
import Badge from "@mui/material/Badge";
import { grey } from "@mui/material/colors";
import { AddCircle, RemoveCircle, PaletteOutlined as PaletteIcon, LabelOutlined as LabelIcon, DeleteOutlineOutlined as DeleteIcon, FileCopyOutlined as CopyIcon, CheckBoxOutlined as CheckBoxIcon, IndeterminateCheckBoxOutlined as HideCheckBoxIcon } from "@mui/icons-material";

const useStyles = makeStyles()((theme) => ({
  timerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: ".8rem",
    marginBottom: "1rem",
  },
  button: {
    padding: "0rem",
    marginLeft: "0.5rem",
  },
  customAvatar: {
    float: "right",
    // margin: ".7rem",
    // marginRight: "1.2rem",
    fontSize: ".8rem",
  },
  absoluteButton: {
    htmlColor: theme.custom.palette.iconHighlight,
    float: "right",
    margin: ".7rem",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    borderColor: theme.custom.palette.itemBorderColor,
    borderWidth: theme.spacing(0.1),
    borderStyle: "solid",
  },
  textTitle: {
    ...theme.custom.fontFamily.metropolis,
    padding: theme.spacing(1.5, 2, 0, 2),
    fontWeight: 500,
    fontSize: "1rem",
    color: theme.palette.text.primary,
    lineHeight: theme.spacing(0.18),
  },
  barWrapper: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(1, 2),
    justifyContent: "space-between",
  },
  onWorkLoadBarWrapper: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(1, 2),
    justifyContent: "flex-end",
  },
}));

export default function ({ noteItem, isEditMode }) {
  const { classes } = useStyles();
  const theme = useTheme();
  const [isHovered, setHovered] = useState(false);

  const [color, setColor] = useState(noteItem.color);
  const [title, setTitle] = useState(noteItem.title);
  const [isCheckBoxMode, setCheckBoxMode] = useState(noteItem.isCheckBoxMode);
  const [pinned, setPinned] = useState(noteItem.pinned);
  const [labels, setLabels] = useState(noteItem.labels || []);
  const [noteinputs, setNotes] = useState(noteItem.notes || []);
  const [workload, setWorkload] = useState(noteItem.workload);

  const [, { setNoteInEditMode }] = useUiStore();
  const [{ isWorkTrackingView }, { toggleWorkTracking }] = useUserStore();
  const [, dispatchTodo] = useTodosStore();
  // const badgeRef = useRef(null);
  // console.log("esto es badgeRef de TodoItem", badgeRef);
  // console.log("TodoItem, noteItem.labels:", noteItem.labels);


  useEffect(() => {
    //console.log("esto es noteItem.workload del useEffect de TodoItem: ", noteItem.workload);
    if (noteItem.workload === 0) {
      setWorkload(0);
    }
    //return () => console.log("esto es noteItem.workload del return del useEffect de TodoItem: ", noteItem.workload);
  }, [noteItem]);

  // const chota = todos.find((todo) => todo._id === noteItem._id);

  const updateWorkloadTime = (operation) => {
    if (operation === "-") {
      if (workload - 10 >= 0) {
        //badgeRef.current = badgeRef.current - 10;
        setWorkload((prevWorkload) => prevWorkload - 10);
        updateTodoItem({ workload: workload - 10 });
      }
    } else if (operation === "+") {
      //badgeRef.current = badgeRef.current + 10;
      setWorkload((prevWorkload) => prevWorkload + 10);
      updateTodoItem({ workload: workload + 10 });
    }
    /*
     */
  };
  const updateCheckboxMode = (isCheckBoxMode) => {
    setCheckBoxMode(isCheckBoxMode);
    updateTodoItem({ isCheckBoxMode });
  };
  const updatePinned = () => {
    //console.log("esto es pinned de TodoItem updatePinned: ", pinned);
    setPinned((prevPinned) => !prevPinned);
    updateTodoItem({ pinned });
  };
  const updateColor = (color) => {
    setColor(color);
    updateTodoItem({ color });
  };
  const updateLabels = (labels) => {
    // console.log("esto es labels de TodoItem", labels);
    setLabels(labels);
    updateTodoItem({ labels: labels /* .map((label) => label._id) */ });
  };
  const onAfterEdit = () => {
    updateTodoItem({});
    setNoteInEditMode("");
  };
  const createOrReplaceNote = (notes) => {
    //console.log("create or replace notes!");
    //console.log({ notes });
    if (notes) {
      notes.forEach((note) => {
        const doc = {
          _id: note._id,
          _type: "note",
          isCompleted: note.isCompleted,
          text: note.text,
        };
        console.log({ doc });
        if (doc._id) {
          client.createOrReplace({ ...doc });
        } else {
          client.create({ ...doc });
          console.log(note);
        }
      });
    }
  };

  // EL WORKLOAD DESPLEGABLE QUE SE ESCONDA SOLO ONBLUR Y QUE MUESTRE UN TOTAL?
  // AGREGAR ESAS TOST O COMO CARAJO SE LLAMEN QUE SE ACTIVAN Y TE MUESTRAN XXX
  // CADA VEZ QUE SE SUMEN 10 MINUTOS EN ALGUNA TAREA? O NO?
  // Y SI EL BOTON DE GUARDAR PARA PASAR LOS REGISTROS A LA TABALA DE WORKLOAD HECHO
  // LO DEJO COMO UN BOTON FLOTANTE QUE SE ACTIVA CUANDO SE ACTIVA EL MODO? EN ESE CASO
  // EL PROBLEMA SERIA QUE TENDRIA QUE PODER CONFIRMARSE O MODIFCIARSE LA FECHA DE ALGUNA MANERA

  const updateTodoItem = (item) => {
    // console.log("TodoItem, updateTodoItem, item: ", item);
    const notesToConsider = item.notes || noteinputs;
    const labelsToConsider = item.labels || labels;
    const localUpdatedDoc = {
      _id: noteItem._id,
      color: item.color || color,
      title: item.title || title,
      isCheckBoxMode: item.isCheckBoxMode || isCheckBoxMode,
      pinned: item.pinned || pinned,
      labels: labelsToConsider,
      notes: notesToConsider.map((note, index) => 
        ({ text: note.text, isCompleted: note.isCompleted, _key: index + 1 })),
      workload: item.workload === 0 ? 0 : item.workload || noteItem.workload,
    };
    const updatedDoc = {
      ...localUpdatedDoc,
      labels: labelsToConsider.map((label) =>
        ({ _key: label._id, _weak:true, _type: "reference", _ref: label._id })),
    };

    client
      .patch(noteItem._id)
      .set({ ...updatedDoc })
      .commit()
      .then((res) => {
        // console.log("TodoItem, uptdateTodoItem, res: ", res)
      })
      .catch((err) => console.error(err.message));

    dispatchTodo({ type: "UPDATED", payload: localUpdatedDoc });
  };

  // console.log("TodoItem", noteItem);

  return (
    <Paper
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={classes.wrapper}
      elevation={isHovered || isEditMode ? 2 : 0}
      style={{ backgroundColor: theme.custom.palette.noteBackground[color] }}
      >
      <ClickAwayListener onClickAway={isEditMode ? () => onAfterEdit() : () => {}}>
        <div onClick={() => setNoteInEditMode(noteItem._id)}>
          {isEditMode ? null : isWorkTrackingView ? (
            <Avatar
              className={classes.customAvatar}
              component={"span"}
              sx={{
                bgcolor: grey[700],
                width: "1.7rem",
                height: "1.7rem",
                margin:"1rem !important"
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {workload}
            </Avatar>
          ) : (
            <Tooltip title="pinealo!">
              <IconButton
                className={classes.absoluteButton}
                size="small"
                aria-label={pinned ? "Unpin" : "Pin"}
                onClick={() => updatePinned()}
                children={pinned 
                  ? <BsPinFill /> 
                  : <BsPin />}
                />
            </Tooltip>
          )}

          <ContentTitle
            onWorkloadMode={isWorkTrackingView}
            onWorkTrackingView={isWorkTrackingView}
            title={title}
            setTitle={setTitle}
            isEditMode={isEditMode}
            workload={workload}
            />

          <Content
            notes={noteinputs}
            setNotes={setNotes}
            createOrReplaceNote={createOrReplaceNote}
            isEditMode={isEditMode}
            isCheckboxMode={isCheckBoxMode}
            updateTodoItem={updateTodoItem}
            id={noteItem._id}
            />
        </div>
      </ClickAwayListener>

      <LabelsBar
        // labels={noteItem.labels}
        setLabels={setLabels}
        noteItem={noteItem}
      />

      {isEditMode ? null : isWorkTrackingView ? (
       <div
        className={isWorkTrackingView
          ? classes.timerWrapper 
          : classes.optionsWrapper}>
          {/* <div className={classes.buttonWrapper}> */}
            <Tooltip title="Minus time">
              <IconButton
                size="small"
                aria-label="minus time"
                onClick={() => updateWorkloadTime("-")}
                className={classes.button}
                >
                <RemoveCircle
                  htmlColor={theme.custom.palette.iconColor}
                  fontSize="small"
                  />
              </IconButton>
            </Tooltip>
          {/* </div> */}

          {/* <div className={classes.buttonWrapper}> */}
            <Tooltip title="Plus time">
              <IconButton
                size="small"
                aria-label="plus time"
                onClick={() => updateWorkloadTime("+")}
                className={classes.button}
                >
                <AddCircle
                  htmlColor={theme.custom.palette.iconColor}
                  fontSize="small"
                  />
              </IconButton>
            </Tooltip>
          {/* </div> */}
       </div>
      ) : (
        <Fade in={isHovered || isEditMode}>
          <div
            className={isWorkTrackingView 
              ? classes.onWorkLoadBarWrapper 
              : classes.barWrapper}>
            <ActionsBar
              updateWorkloadTime={updateWorkloadTime}
              noteItem={noteItem}
              color={color}
              setColor={updateColor}
              labels={labels}
              setLabels={updateLabels}
              setCheckboxMode={updateCheckboxMode}
              isCheckboxMode={isCheckBoxMode}
              isCreateMode={false}
              />
          </div>
        </Fade>
      )}
    </Paper>
  );
}
