/* eslint-disable import/no-anonymous-default-export */
import React, { useRef, useState, useCallback } from "react";
import { useTheme, styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import {
  AppBar,
  Avatar,
  Toolbar,
  IconButton,
  Typography,
  useScrollTrigger,
  useMediaQuery,
  Icon,
  } from "@mui/material";
import { 
  Timer,
  FaceOutlined as FaceIcon,
  DashboardOutlined as TileViewIcon,
  Brightness4Outlined as ToggleDarkModeIcon,
  Brightness5Outlined as ToggleLightModeIcon,
  SearchOutlined as SearchIcon,
  Menu as MenuIcon,
  ViewAgendaOutlined as ListIcon,
  } from "@mui/icons-material";
import { TbLayoutList as TbListIcon } from "react-icons/tb";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import logo from "../../assets/logo-keep.png";
import ProfilePopover from "./ProfilePopover";
import WorkloadPopover from "./WorkloadPopover";
import SettingsPopover from "./SettingsPopover";
import SearchBar from "./SearchBar";
import { client } from "../../client";
import { useUserStore, useUiStore } from "../../store";

const useStyles = makeStyles()((theme) => {
  return {
    grow: {
      flexGrow: 1,
    },
    containerBorder: {
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",
      borderBottomColor: theme.custom.palette.itemBorderColor,
    },
    menuButton: {
      [theme.breakpoints.up("md")]: {
        marginRight: theme.spacing(1),
      },
    },
    logoContainer: {
      display: "flex",
      justifyContent: "stretch",
    },
    logo: {
      display: "none",
      height: theme.spacing(5.5),
      padding: theme.spacing(0, 1, 0, 0),
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    title: {
      ...theme.custom.fontFamily.metropolis,
      display: "none",
      [theme.breakpoints.up("xs")]: {
        display: "flex",
        alignItems: "center",
      },
    },
    searchbarContainer: {
      flexGrow: 1,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        flexGrow: 0,
        width: theme.spacing(90),
        marginLeft: theme.spacing(9),
      },
    },
  };
});

export default function () {
  const menuId = "primary-buttons-menu";
  const { classes } = useStyles();
  const theme = useTheme();
  const [isSearchShowingInMobile, setSearchShowing] = useState(false);
  const [isWorkloadPopoverOpen, setWorkloadPopoverOpen] = useState(false);
  const [isSettingsPopoverOpen, setSettingsPopoverOpen] = useState(false);
  const [isProfilePopoverOpen, setProfilePopoverOpen] = useState(false);
  const [{ _id, given_name, family_name, picture, isDarkMode, isListView, isWorkTrackingView, isWorkloadView },
   { toggleDarkMode, toggleListView, toggleWorkTracking }] = useUserStore();
  const [{ onWorkloadMode }, { toggleNavBar }] = useUiStore();
  const profileMenuRef = useRef();
  const workloadMenuRef = useRef();
  const settingsMenuRef = useRef();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const onDarkModeToggle = useCallback(async () => {
    toggleDarkMode();
    await client
      .patch(_id)
      .set({ darkMode: !isDarkMode })
      .commit()
      // .then(res => console.log('Darkmode changed', res))
      .catch((err) => console.error("Oh no, the update failed: ", err));
  }, [_id, toggleDarkMode, isDarkMode]);

  const onViewToggle = useCallback(async () => {
    toggleListView();
    await client
      .patch(_id)
      .set({ listMode: !isListView })
      .commit()
      // .then((res) => console.log("ListView changed", res))
      .catch((err) => console.error("Oh no, the update failed: ", err));
  }, [_id, toggleListView, isListView]);

  const onWorkTrackingToggle = useCallback(async () => {
    toggleWorkTracking();
    await client
      .patch(_id)
      .set({ isWorkTrackingView: !isWorkTrackingView })
      .commit()
      .then((res) => console.log("Work tracking view changed", res))
      .catch((err) => console.error("Oh no, the update failed: ", err));
  }, [_id, toggleWorkTracking, isWorkTrackingView]);

  //  console.log("esto es onWorkloadMode de AppBar: ", onWorkloadMode);

  return (
    <div className={classes.grow}>
      <AppBar elevation={trigger ? 4 : 0} className={trigger ? null : classes.containerBorder}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            aria-label="open drawer"
            onClick={toggleNavBar}
            children={<MenuIcon htmlColor={theme.custom.palette.iconColor} />}
            />

          {isMobile ? (
            isSearchShowingInMobile ? (
              <SearchContainer onSearchClose={() => setSearchShowing(false)} />
            ) : (
              <LogoContainer />
            )
          ) : (
            <>
              <LogoContainer />
              <SearchContainer onSearchClose={() => setSearchShowing(false)} />
            </>
          )}
          <div className={classes.grow} />
          {isMobile && !isSearchShowingInMobile ? (
            <div>
              <IconButton
                aria-label="search"
                aria-controls={menuId}
                onClick={() => setSearchShowing(true)}
                children={<SearchIcon htmlColor={theme.custom.palette.iconColor} />}
                />
            </div>
          ) : null}

          {isMobile ? null : (
            <>
              {/* <div>
              <IconButton
                ref={workloadMenuRef}
                aria-label={onWorkTrackingToggle 
                              ? "close workload elementss"
                              : "workload view"}
                aria-controls={menuId}
                onClick={onWorkTrackingToggle}
                children={<Timer htmlColor={theme.custom.palette.iconColor} />}
                />
            </div> */}
            </>
          )}
          <div>
            {/* <IconButton
              aria-label="toggle dark theme"
              aria-controls={menuId}
              onClick={onDarkModeToggle}
              children={isDarkMode
                          ? <ToggleLightModeIcon htmlColor={theme.custom.palette.iconColor} />
                          : <ToggleDarkModeIcon htmlColor={theme.custom.palette.iconColor} />}
              /> */}
          </div>
          {isMobile ? null : (
            <div>
              <IconButton
                aria-label={isListView ? "toggle tile view" : "toggle list view"}
                aria-controls={menuId}
                onClick={onViewToggle}
                children={isListView 
                              ? <GridViewOutlinedIcon htmlColor={theme.custom.palette.iconColor} />
                              : <ListIcon />}
              />
            </div>
          )}
          <div>
            <IconButton
              ref={settingsMenuRef}
              onClick={()=>setSettingsPopoverOpen(true)}
              aria-label={onWorkloadMode ? "todos view" : "work tracking"}
              aria-controls={menuId}
              children={<SettingsOutlinedIcon/>}
            />
          </div>
          <div style={{ marginLeft: "1.5rem" }}>
            <IconButton 
              edge="end"
              ref={profileMenuRef}
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={() => setProfilePopoverOpen(true)}
              children={<Avatar alt={given_name + " " + family_name}
                          src={picture}
                          sx={{ width: 30, height: 30 }}
                          children={<FaceIcon htmlColor={theme.custom.palette.iconColor} fontSize="large" />}
                          imgProps={{ referrerPolicy: "no-referrer" }} />} 
              />
          </div>
        </Toolbar>
        <SettingsPopover
          anchorEl={settingsMenuRef.current}
          isOpen={isSettingsPopoverOpen}
          onClose={() => setSettingsPopoverOpen(false)}
          />
        {/* 
        <WorkloadPopover
          anchorEl={workloadMenuRef.current}
          isOpen={isWorkloadPopoverOpen}
          onClose={() => setWorkloadPopoverOpen(false)}
          />
         */}
        <ProfilePopover
          anchorEl={profileMenuRef.current}
          isOpen={isProfilePopoverOpen}
          onClose={() => setProfilePopoverOpen(false)}
          />
      </AppBar>
    </div>
  );
}

function LogoContainer() {
  const { classes } = useStyles();
  return (
    <div className={classes.logoContainer}>
      <img className={classes.logo} src={logo} alt={"logo"} />
      <Typography
        color="textSecondary"
        className={classes.title}
        variant="h6"
        noWrap
        >
        Notes
      </Typography>
    </div>
  );
}

function SearchContainer({ onSearchClose }) {
  const { classes } = useStyles();
  return (
    <div className={classes.searchbarContainer}>
      <SearchBar ml={8} onSearchClose={onSearchClose} />
    </div>
  );
}
