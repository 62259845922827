/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import ContentList from "./ContentList";
import ContentText from "./ContentText";
import { makeStyles } from "tss-react/mui";
import {useTheme} from '@mui/material';

const useStyles = makeStyles()((theme)=>({
  content: {
      padding:`${theme.spacing(.5)} 0px ${theme.spacing(1)} 0px`,
    }
}))

export default function ({ notes, setNotes, isEditMode, isCheckboxMode, updateTodoItem, createOrReplaceNote, id }) {
  const {classes} = useStyles()
  const theme = useTheme()
  //console.log({ notes });

  return (
        <div className={classes.content}>
          {isCheckboxMode 
              ? <ContentList
                  notes={notes}
                  setNotes={setNotes}
                  createOrReplaceNote={createOrReplaceNote}
                  isEditMode={isEditMode}
                  updateTodoItem={updateTodoItem}
                  />
              : <ContentText 
                  notes={notes}
                  setNotes={setNotes}
                  createOrReplaceNote={createOrReplaceNote}
                  isEditMode={isEditMode}
                  updateTodoItem={updateTodoItem}
                  id={id}
                  />
              }
        </div>
  )
}        
