/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { makeStyles } from "tss-react/mui";
import { Chip } from "@mui/material";
import { client } from "../../client";
import { useTodosStore } from "../../store";

const useStyles = makeStyles()((theme) => {
  return {
    labelsWrapper: {
      display: "flex",
      flexWrap: "wrap",
      padding: theme.spacing(0, 1.25),
    },
    labelWrapper: {
      padding: theme.spacing(0.36, 0.5),
    },
    label: {
      ...theme.custom.fontFamily.metropolis,
      fontSize: theme.typography.overline.fontSize,
      color: theme.palette.text.primary,
      backgroundColor: theme.custom.palette.labelBackground,
    },
  };
});

export default function ({ setLabels, noteItem }) {
  const { classes } = useStyles();
  const [, dispatchTodo] = useTodosStore();
  // console.log("Labels, noteitem: ", noteItem);

  const handleDelete = (id) => {
    // console.log("Labels, handleDelete, id y labels: ", id, noteItem?.labels);
    const newLabels = noteItem?.labels.filter((label) => label._id !== id);
    const newLabelsRefs = newLabels?.map((label) => ({ _key: label._id, _ref: label._id, _type: "reference" }));
    setLabels(newLabels);

    const localUpdatedDoc = {
      ...noteItem,
      labels: newLabels,
    };

    //console.log("esto es filteredLabels de Labels:", { labelsReferences });
    client
      .patch(noteItem._id)
      .set({ labels: newLabelsRefs })
      .commit()
      .then((res) => {
        console.log("Labels, handleDelete, res: ", res);
        dispatchTodo({ type: "UPDATED", payload: localUpdatedDoc });
        //console.log("esto es el response de handleDelete de Labels: ", response)
        return;
      });
  };

  // console.log("Labels, labels:", noteItem?.labels);

  return (
    <div className={classes.labelsWrapper}>
      {noteItem?.labels.map(({ _id, name }) => {
        // console.log("Labels, labelItem:", { labelItem });
        return (
          <div key={_id} className={classes.labelWrapper}>
            <Chip label={name} size="small" classes={{ root: classes.label }} onDelete={() => handleDelete(_id)} />
          </div>
        );
      })}
    </div>
  );
}
