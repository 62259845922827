import React, { createContext, useContext, useState, useReducer } from "react";

const TodosContext = createContext([]);
const LabelsContext = createContext([]);
const UserContext = createContext(null);
const UiContext = createContext(null);
const WorkRecordsContext = createContext([]);

const reducer = (state = [], action = {}) => {
  const mutatedItem = action.payload;
  if (!mutatedItem) {
    return;
  }

  const mutatedIndex = state.findIndex((item) => item._id === mutatedItem._id);
  switch (action.type) {
    case "CREATED":
      if (mutatedIndex < 0) {
        state.push(mutatedItem);
      }
      break;
    case "DELETED":
      if (mutatedIndex >= 0) {
        state.splice(mutatedIndex, 1);
      }
      break;
    case "UPDATED":
      state[mutatedIndex] = mutatedItem;
      break;
    default:
  }
  return [...state];
};

export function TodosProvider({ children, todos }) {
  const [state, dispatch] = useReducer(reducer, todos);
  return <TodosContext.Provider value={[state, dispatch]}>
              {children}
        </TodosContext.Provider>
}

export function LabelsProvider({ children, labels }) {
  const [state, dispatch] = useReducer(reducer, labels);
  return <LabelsContext.Provider value={[state, dispatch]}>
            {children}
          </LabelsContext.Provider>;
}

export function UserProvider({ children, user }) {
  const [isDarkMode, setDarkMode] = useState(user && user.darkMode);
  const [isListView, setListView] = useState(user && user.listMode);
  const [isWorkTrackingView, setWorkTrackingView] = useState(user && user.workTracking);
  const [isWorkloadView, setWorkloadView] = useState(user && user.workloadView);
  const userValue = [
    {
      given_name: user && user.given_name,
      family_name: user && user.family_name,
      email: user && user.email,
      picture: user && user.picture,
      isDarkMode,
      isListView,
      isWorkTrackingView,
      isWorkloadView,
      _id: user._id,
    },
    {
      toggleDarkMode: () => setDarkMode((prevDarkMode) => !prevDarkMode),
      toggleListView: () => setListView((prevListView) => !prevListView),
      toggleWorkTracking: () => setWorkTrackingView((prevTrackingView) => !prevTrackingView),
      setWorkloadView,
    },
  ];
  return <UserContext.Provider value={userValue}>{children}</UserContext.Provider>;
}
// CHEQUEAR LOS CAMBIOS DE LOS NOMBRES DE LAS VARIABLES SIGUIENDO LOS CAMPOS DE USER DEL BACK
export function UiProvider({ children }) {
  const [isNavBarOpen, setNavBarOpen] = useState(false);
  const [noteInEditMode, setNoteInEditMode] = useState("");
  const [selectedLabelId, setSelectedLabelId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const uiValue = [
    {
      selectedLabelId,
      isNavBarOpen,
      noteInEditMode,
      searchTerm,
    },
    {
      toggleNavBar: () => setNavBarOpen(!isNavBarOpen),
      setNavBarOpen, // toggleNavBar?
      setNoteInEditMode,
      setSelectedLabelId,
      setSearchTerm,
    },
  ];
  return <UiContext.Provider value={uiValue}>
            {children}
         </UiContext.Provider>;
}

export function WorkRecorsProvider({ children, records }) {
  return <WorkRecordsContext.Provider value={records}>
            {children}
         </WorkRecordsContext.Provider>;
}

export const useTodosStore = () => useContext(TodosContext);
export const useLabelsStore = () => useContext(LabelsContext);
export const useUserStore = () => useContext(UserContext);
export const useUiStore = () => useContext(UiContext);
export const useWorkRecord = () => useContext(WorkRecordsContext);
