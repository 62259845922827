/* eslint-disable import/no-anonymous-default-export */
import React, { useRef, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { IconButton, Tooltip } from "@mui/material";
import {
  AddCircle,
  RemoveCircle,
  PaletteOutlined as PaletteIcon,
  LabelOutlined as LabelIcon,
  DeleteOutlineOutlined as DeleteIcon,
  FileCopyOutlined as CopyIcon,
  CheckBoxOutlined as CheckBoxIcon,
  IndeterminateCheckBoxOutlined as HideCheckBoxIcon
  } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import ColorPopover from "./ColorPopover";
import LabelPopover from "./LabelPopover";
import { useTodosStore, useUserStore } from "../../store";
import { client } from "../../client";

const useStyles = makeStyles()((theme) => {
  return {
    isWorkTrackingViewoptionsWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    optionsWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    optionWrapperFirst: {
      padding: theme.spacing(0, 1, 0, 0),
    },
    optionWrapperLast: {
      padding: theme.spacing(0, 0, 0, 1),
    },
    optionWrapper: {
      padding: theme.spacing(0, 1),
    },
    isWorkTrackingViewOptionWrapper: {
      padding: theme.spacing(0),
    },
    barClose: {},
  };
});

export default function (props) {
  const { noteItem, labels, setLabels, color, setColor,
       setCheckboxMode, isCreateMode, isCheckboxMode, updateWorkloadTime } = props;
  const { classes } = useStyles();
  const [{ isWorkTrackingView }] = useUserStore();
  const theme = useTheme();
  const refActionColor = useRef();
  const refActionLabel = useRef();
  const [isColorPopoverOpen, setColorPopoverOpen] = useState(false);
  const [isLabelPopoverOpen, setLabelPopoverOpen] = useState(false);
  const [, dispatchTodo] = useTodosStore();


  const duplicateNote = () => {
    const newDoc = { ...noteItem };
    delete newDoc._id;
    client
      .create(newDoc)
      .then((res) => dispatchTodo({ type: "CREATED", payload: res }))
      .catch((err) => console.log("Oh no, there was an error duplicating the note!", err));
  };
  const deleteNote = () => {
    client
      .delete(noteItem._id)
      .then((mutation) => {
        dispatchTodo({ type: "DELETED", payload: mutation.results[0].document });
      })
      .catch((err) => console.log("Oh no, there was an error deleting the note!", err));
  };

  return (
    <>
      <div className={isWorkTrackingView 
            ? classes.isWorkTrackingViewoptionsWrapper 
            : classes.optionsWrapper}
            >
        {/* {isWorkTrackingView ? ( */}
        {/* <>
            <div className={classes.isWorkTrackingViewOptionWrapper}>
              <Tooltip title="Minus time">
                <IconButton size="small" aria-label="minus time" onClick={() => updateWorkloadTime("-")}>
                  <RemoveCircle htmlColor={theme.custom.palette.iconColor} fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>

            <div className={classes.isWorkTrackingViewOptionWrapper}>
              <Tooltip title="Plus time">
                <IconButton size="small" aria-label="plus time" onClick={() => updateWorkloadTime("+")}>
                  <AddCircle htmlColor={theme.custom.palette.iconColor} fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          </> */}
        {/* ) : ( */}
        <>
          <div className={classes.optionWrapperFirst}>
            <Tooltip title="Change color">
              <IconButton
                size="small"
                aria-label="change color"
                ref={refActionColor}
                onClick={() => setColorPopoverOpen(true)}
                >
                <PaletteIcon
                  htmlColor={theme.custom.palette.iconHighlight}
                  fontSize="small"
                  />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.optionWrapper}>
            <Tooltip title="Show checkboxes">
              <IconButton
                size="small"
                aria-label="show checkboxes"
                onClick={() => setCheckboxMode(!isCheckboxMode)}
                >
                {isCheckboxMode 
                  ? <HideCheckBoxIcon
                      htmlColor={theme.custom.palette.iconHighlight}
                      fontSize="small"
                      />
                  : <CheckBoxIcon 
                      htmlColor={theme.custom.palette.iconHighlight}
                      fontSize="small"
                      />
                } 
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.optionWrapper}>
            <Tooltip title="Change labels">
              <IconButton
                size="small"
                aria-label="change labels"
                ref={refActionLabel}
                onClick={() => setLabelPopoverOpen(true)}
                >
                <LabelIcon
                  htmlColor={theme.custom.palette.iconHighlight}
                  fontSize="small"
                  />
              </IconButton>
            </Tooltip>
          </div>
          {isCreateMode ? null : (
            <>
              <div className={classes.optionWrapper}>
                <Tooltip title="Make a copy">
                  <IconButton
                    size="small"
                    aria-label="make a copy"
                    onClick={duplicateNote}
                    >
                    <CopyIcon
                      htmlColor={theme.custom.palette.iconHighlight}
                      fontSize="small"
                      />
                  </IconButton>
                </Tooltip>
              </div>

              <div className={classes.optionWrapperLast}>
                <Tooltip title="Delete note">
                  <IconButton
                    size="small"
                    aria-label="delete note"
                    onClick={deleteNote}
                    >
                      <DeleteIcon
                        htmlColor={theme.custom.palette.iconHighlight}
                        fontSize="small"
                        />
                  </IconButton>
                </Tooltip>
              </div>
            </>
          )}
        </>
        {/* )} */}
      </div>
      <ColorPopover
        anchorEl={refActionColor.current}
        isOpen={isColorPopoverOpen}
        onClose={() => setColorPopoverOpen(false)}
        currentColor={color}
        onColorSelect={(color) => setColor(color)}
        />
      <LabelPopover
        anchorEl={refActionLabel.current}
        isOpen={isLabelPopoverOpen}
        setLabels={setLabels}
        labels={labels}
        onClose={() => setLabelPopoverOpen(false)}
        />
    </>
  );
}
