import React from "react";
import { makeStyles } from "tss-react/mui";
import { Popover, Typography, Avatar, Divider, useTheme, Stack, Box } from "@mui/material";
import { FaceOutlined as FaceIcon } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../store";

const useStyles = makeStyles()((theme) => {
  return {
    popover: {
      background: theme.custom.palette.profilePopColor,
      width: theme.spacing(35),
    },
    container: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      overflow: "hidden",
      padding: theme.spacing(2.5, 2),
      alignItems: "center",
    },
    avatar: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      margin: theme.spacing(1),
      borderColor: theme.palette.grey[800],
      background: theme.palette.background.default,
    },
    userName: {
      ...theme.custom.fontFamily.metropolis,
      color: theme.palette.grey[700],
      fontSize: ".9rem",
      fontWeight: 600,
      lineHeight: "1rem",
      cursor: "default",
    },
    userEmail: {
      ...theme.custom.fontFamily.roboto,
      fontSize: "0.8rem",
      cursor: "default",
      color: theme.palette.grey[600],
    },
    bar: {
      padding: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: theme.palette.grey[100],
      cursor: "pointer",
    },
    logoutIcon: {
      color: theme.palette.grey[600],
      marginRight: theme.spacing(1),
      fontSize: "1.2rem",
    },
    signOutText: {
      color: theme.palette.grey[600],
      fontSize: ".9rem",
    },
    divider: {
      color: theme.custom.palette.itemBorderColor,
      // color: "red",
    },
  };
});

export default function ProfilePopover({ anchorEl, isOpen, onClose }) {
  const { classes } = useStyles();
  const theme = useTheme();
  const id = isOpen ? "profile-popover" : undefined;
  const [{ given_name, family_name, email, picture }] = useUserStore();
  const navigate = useNavigate();
  // console.log("Profilepopover, picture:", picture);

  function logout() {
    sessionStorage.removeItem("user");
    navigate("/login");
  }

  return (
    <div>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: classes.popover,
        }}
      >
        <Box className={classes.container}>
          <Stack
            // spacing={0}
            direction="row"
            alignItems="center"
            justifyContent={"center"}
          >
            <Avatar className={classes.avatar} alt={given_name + " " + family_name} src={picture} children={<FaceIcon htmlColor={theme.custom.palette.iconColor} fontSize="medium" />} imgProps={{ referrerPolicy: "no-referrer" }} />
            <Stack>
              <Typography className={classes.userName} component="span">
                {given_name + " " + family_name}
              </Typography>
              <Typography className={classes.userEmail} component="span">
                {email}
              </Typography>
            </Stack>
          </Stack>
        </Box>

        <Divider className={classes.divider} />

        <div className={classes.bar} onClick={logout}>
          <LogoutIcon className={classes.logoutIcon} />
          <Typography className={classes.signOutText} noWrap>
            Sign Out
          </Typography>
        </div>
      </Popover>
    </div>
  );
}
