import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { 
  CardActions,
  Collapse,
  Popover,
  Typography,
  Box,
  List,
  ListItem,
  TextField
  } from "@mui/material";
import { useTodosStore, useUserStore } from "../../store";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { client } from "../../client";
import { useEffect } from "react";

// ARREGLAR EL ERROR QUE TE TIRA CUANDO LE METES UNA LABEL AL TODOS
// ARREGLAR EL ERROR QUE TE TIRA CUANDO LE METES UNA LABEL AL TODOS
// ARREGLAR EL ERROR QUE TE TIRA CUANDO LE METES UNA LABEL AL TODOS
// ARREGLAR EL ERROR QUE TE TIRA CUANDO LE METES UNA LABEL AL TODOS
// ARREGLAR EL ERROR QUE TE TIRA CUANDO LE METES UNA LABEL AL TODOS
// CREATE DELETE DATA IN SPECIFIC DATE QUERY


const useStyles = makeStyles()((theme) => {
  return {
    popover: {
      width: "185px",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
    typo: {
      fontSize: "14px",
      color: "#3c4043",
    },
    list: {
      width: "100%",
    },
    listItemRoot: {
      padding: theme.spacing(".3rem", "1rem", ".3rem", "1rem"),
    },
    listItemSelected: {
      backgroundColor: `${theme.palette.grey[200]} !important`,
    },
    cardActions: {
      pointerEvents: "auto",
      justifyContent: "start",
      padding: "0px",
    },
    dateTimePickerInput: {
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        width: "4.5rem",
                        height: ".9rem !important",
                      },
                      "& .MuiFormControl-root": { height: ".9rem !important" },
                      "& .MuiInputBase-root": { height: ".9rem !important" },
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                        color: "#3c4043",
                        paddingLeft: "0rem",
                        paddingTop: "0rem",
                        paddingBottom: "0rem",
                        width: "4.5rem",
                      },
                      "& .MuiSvgIcon-root": { fontSize: "14px" },
                      "& .MuiButtonBase-root": { padding: "0px" },
                    },

  }
});

const LabelItem = ({ text }) => {
  const { classes } = useStyles();
  return <Typography component="span" className={classes.typo} children={text} />;
};

export default function SettingsPopover({ anchorEl, isOpen, onClose }) {
  const { classes } = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [date, setDate] = useState(new dayjs());
  const [{ isWorkTrackingView, isDarkMode, ...user },
  { toggleWorkTracking, toggleDarkMode }] = useUserStore();
  const [todos, dispatchTodo] = useTodosStore();
  const id = isOpen ? "settings-popover" : undefined;
  const [disabled, setDisabled] = useState(false)
  // console.log("SettingsPopover, todos del store:", todos);

  useEffect(()=>{
    setDisabled(!todos.some(todo=>todo.workload >0))
  },[todos])
/*
*/
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  function handleSwitch() {
    toggleWorkTracking(!isWorkTrackingView);
  }
  const handleDatePickerChange = (newDate) => {
    setDate(newDate);
  };
  const relevantTodos = () =>{
    return todos.filter(todo=>todo.workload>0)
  }
  const handleReset = () => {
    const todos = relevantTodos()
    todos.forEach(todo=>{
      client
        .patch(todo._id)
        .set({...todo, workload:0})
        .commit()
        .then()
        .catch(err=>console.error(err))
      dispatchTodo({ type: "UPDATED", payload:{...todo, workload:0}})
      })
  }
  const handleSaveWorkload = () => {
        const todos = relevantTodos()
        todos
          .forEach((todo) => {
          const { createdAt, _id, _rev, updatedAt, labels, ...rest } = todo
          const done = {
            ...rest,
            _type: "workloadTracking",
            date: date.format("YYYY-MM-DD"),
            user: [{_type:"reference", _key: user._id, _ref:user._id }],
            labels: labels.map((label) => 
                ({ _key: label._id, _type: "reference", _ref: label._id })),
            }
        client.create(done);
        });
      handleReset()
    // toggleWorkTracking(!isWorkTrackingView);
  }

  // const deleteDate =()=>{
  // }


  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      classes={{
        paper: classes.popover,
      }}
    >
      <Box className={classes.container}>
        <List className={classes.list}>
          <ListItem
            button
            disableRipple
            classes={{
              root: classes.listItemRoot,
            }}
            onClick={toggleDarkMode}
            children={<LabelItem text={isDarkMode ? "Disable dark theme" : "Enable dark theme"} />}
          />
          <ListItem
            button
            disableRipple
            selected={expanded}
            classes={{
              selected: classes.listItemSelected,
              root: classes.listItemRoot,
            }}
            onClick={handleExpandClick}
            children={
              <CardActions
                disableSpacing
                aria-expanded={expanded}
                aria-label="show more"
                classes={{
                  root: classes.cardActions,
                }}
              >
                <LabelItem text={"Work tracking settings"} />
              </CardActions>
            }
          />

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <ListItem
              button
              disableRipple
              onClick={handleSwitch}
              classes={{ root: classes.listItemRoot }}
              children={<LabelItem text={isWorkTrackingView ? "Hide badges" : "Show badges"} />}
            />

            <ListItem
              button
              disableRipple
              classes={{
                root: classes.listItemRoot,
              }}
              disabled={disabled}
              onClick={handleReset}
              children={<LabelItem text="Reset badges" />}
            />

            <ListItem button disableRipple className={classes.listItemRoot}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <LabelItem text={"Set date"} />
                <Typography className={classes.typo} component="span">
                  &nbsp;
                </Typography>
                <DateTimePicker
                  inputFormat={`DD/MM/YYYY`}
                  value={date}
                  onChange={handleDatePickerChange}
                  showDaysOutsideCurrentMonth
                  views={["day", "month", "year"]}
                  renderInput={(params) => {
                    return <TextField {...params} />;
                  }}
                  // components={{  OpenPickerIcon: LocalConvenienceStoreOutlined,}}
                  InputProps={{
                    className: classes.dateTimePickerInput,
                  }}
                  PopperProps={{}}
                  // displayWeekNumber={true}
                />
              </LocalizationProvider>
            </ListItem>

            <ListItem
              button
              disableRipple
              classes={{
                root: classes.listItemRoot,
              }}
              onClick={handleSaveWorkload}
              children={<LabelItem text={"Submit values"} />}
            />

            <ListItem
              button
              disableRipple
              classes={{
                root: classes.listItemRoot,
              }}
              onClick={handleSaveWorkload}
              children={<LabelItem text={`Delete ${date.format("DD/MM/YYYY")} data`} />}
            />
          </Collapse>
        </List>
      </Box>
    </Popover>
  );
}
