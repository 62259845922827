import React from "react";
import { makeStyles } from "tss-react/mui";
import TodoItem from "./TodoItem";

const useStyles = makeStyles()((theme) => {
  return {
    sectionTypo: {
      color: "#5f6368",
      letterSpacing: ".07272727em",
      fontFamily: "Roboto,Arial,sans-serif",
      fontSize: ".6875rem",
      fontWeight: 500,
      lineHeight: "1rem",
      textTransform: "uppercase",
      margin: "32px 10px 2px 10px",
      pointerEvents: "auto",
      visibility: "visible",
    },
    todosWrapper: {
      margin: "0 auto",
      columnWidth: theme.spacing(29),
      columnGap: "0.5rem",
    },
    todoWrapper: {
      width: theme.spacing(29),
      margin: "0 auto",
      breakInside: "avoid",
      pageBreakInside: "avoid",
      padding: "0.5rem 0",
      transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.standard,
      }),
    },
  };
});

export function TodosList(props){
  const { list, title, isMobile, isListView,
           numberOfColumns, width, noteInEditMode } = props
  const { classes } = useStyles();
  // console.log("TodosList, list:", list);
  return (
    <>
      <div 
        className={classes.todosWrapper}
        style={{ columnCount: isListView ? 1 : numberOfColumns, width: width }}
        >
        <div className={classes.sectionTypo}>
          {title}
        </div>
      </div>
      <div
        className={classes.todosWrapper}
        style={{ columnCount: isListView ? 1 : numberOfColumns, width: width }}
        >
        {list.map((noteItem) => (
          <div
            key={noteItem._id}
            className={classes.todoWrapper}
            style={{ width: isMobile || isListView ? "100%" : null }}
            >
            <TodoItem
              noteItem={noteItem}
              isEditMode={noteInEditMode === noteItem._id}
              />
          </div>
        ))}
      </div>
    </>
  );
}
