/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import { Paper, InputBase, Collapse, Button, ClickAwayListener } from "@mui/material";
import TodoActions from "../todo/Actions";
import TodoLabels from "../todo/Labels";
import TodoContent from "../todo/Content";
import { useTodosStore, useUserStore } from "../../store";
import { client } from "../../client";

const useStyles = makeStyles()((theme) => {
  return {
    paperWrapper: {
      transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.short,
      }),
      borderColor: theme.custom.palette.itemBorderColor,
      borderWidth: theme.spacing(0.1),
      borderStyle: "solid",
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
    },
    inputTitleRoot: {
      ...theme.custom.fontFamily.metropolis,
      padding: theme.spacing(1.25, 2),
    },
    inputTitleInput: {
      fontWeight: 500,
      fontSize: "1rem",
      padding: 0,
      lineHeight: "1rem",
      verticalAlign: "middle",
      color: theme.palette.text.primary,
    },
    inputNoteRoot: {
      ...theme.custom.fontFamily.roboto,
      padding: theme.spacing(1.5, 2),
    },
    inputNoteInput: {
      fontWeight: 500,
      fontSize: "0.85rem",
      padding: 0,
      color: theme.palette.text.primary,
    },
    barWrapper: {
      display: "flex",
      flexDirection: "row",
      padding: theme.spacing(1, 2),
      justifyContent: "space-between",
    },
  };
});

export default function ({ onWorkTrackingView }) {
  const { classes } = useStyles();
  const theme = useTheme();
  //const [, createTodoExecute] = useMutation(createTodo);
  const [isFocussed, setFocussed] = useState(false);
  const [title, setTitle] = useState("");
  const [notes, setNotes] = useState([]);
  const [color, setColor] = useState("default");
  const [isCheckBoxMode, setCheckBoxMode] = useState(false);
  const [labels, setLabels] = useState([]);
  const [, dispatchTodo] = useTodosStore();
  const [{ ...user }] = useUserStore();
  // const userId = sessionStorage.getItem("user");
  // console.log("TodoCreate, user: ", user);

  const onCloseClick = () => {
    // console.log("close click", labels);
    //const noteTexts = notes.map((noteItem) => noteItem.text);
    const noteTexts = notes.map((noteItem, index) => ({ _key: index + 1, isCompleted: false, text: noteItem.text }));
    //const labelIds = labels.map((labelItem) => labelItem._id);
    //const labelIds = labels; //.map((labelItem) => labelItem._id); SL

    setFocussed(false);

    if (title || noteTexts.length > 0) {
      const todo = {
        _type: "todo",
        title,
        labels: labels.map((label) => 
          ({ _type: "reference", _weak: true, _key: label._id, _ref: label._id })),
        notes: noteTexts,
        color,
        isCheckBoxMode,
        pinned: false,
        workload: 0,
        userId: { _type: "reference", _key: user._id, _ref: user._id },
      };

      // console.log("TodoCreate, onCloseClick: ", todo);
      client.create(todo).then((created) => {
        // console.log("esto es returned res de la creacion del todo en TodoCreate:", { ...created });
        dispatchTodo({ type: "CREATED", payload: { ...created, labels } });
      });

      setTitle("");
      setNotes([]);
      setColor("default");
      setCheckBoxMode(false);
      setLabels([]);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setFocussed(false)}>
      <Paper elevation={2} classes={{ root: classes.paperWrapper }} style={{ backgroundColor: theme.custom.palette.noteBackground[color], visibility: onWorkTrackingView ? "hidden" : "visible" }}>
        <Collapse classes={{ wrapperInner: classes.wrapper }} in={isFocussed} collapsedSize="2.7rem">
          <InputBase
            placeholder={isFocussed ? "Title" : "Take a note..."}
            classes={{
              root: isFocussed ? classes.inputTitleRoot : classes.inputNoteRoot,
              input: classes.inputTitleInput,
            }}
            inputProps={{ "aria-label": "note title" }}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            onFocus={() => setFocussed(true)}
          />
          {isFocussed ? <TodoContent notes={notes} setNotes={setNotes} isEditMode={true} isCheckBoxMode={isCheckBoxMode} /> : null}
          <TodoLabels labels={labels} />
          <div className={classes.barWrapper}>
            <TodoActions id={""} color={color} setColor={setColor} labels={labels} setLabels={setLabels} setCheckboxMode={setCheckBoxMode} isCreateMode={true} isCheckboxMode={isCheckBoxMode} />
            <div>
              <Button size="small" onClick={onCloseClick} variant="text" sx={{ color: "black", textTransform: "capitalize", fontWeight: "bold" }}>
                Close
              </Button>
            </div>
          </div>
        </Collapse>
      </Paper>
    </ClickAwayListener>
  );
}
