import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Drawer, List, Divider, Typography, useTheme, useMediaQuery } from "@mui/material";
import { 
  PlaylistAddCircle as Records,
  WbIncandescentOutlined as IdeaIcon,
  LabelOutlined as LabelIcon, 
  } from "@mui/icons-material";
import DrawerItem from "./DrawerItem";
import { useUiStore, useLabelsStore, useUserStore } from "../../store";
import { LabelsModal } from "./LabelsModal";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const useStyles = makeStyles()((theme) => {
  return {
    drawer: {
      width: theme.mixins.drawer.minWidth - 100,
      flexShrink: 0,
    },
    drawerPaper: {
      background: theme.palette.background.default,
      width: theme.mixins.drawer.minWidth - 100,
      border: 0,
    },
    sectionTitle: {
      padding: theme.spacing(2, 1, 0, 2),
      color: theme.palette.text.secondary,
    },
    toolbar: theme.mixins.toolbar,
  };
});

export default function NavDrawer({ fetchWorkloadTracking }) {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [labelItems] = useLabelsStore();
  const [{ selectedLabelId, isNavBarOpen },
         { setNavBarOpen, toggleNavBar, setSelectedLabelId }] = useUiStore();
  const [{ isWorktrackView }, { setWorktrackView }] = useUserStore();

  //const [labelItems] = useState(labels);



  const selectAllLabels = () => {
    onDrawerItemSelected("");
  }
  /*
  const settingWorktrackView = () => {
    setWorktrackView(true);
    onDrawerItemSelected("");
  };
  */
  const onDrawerItemSelected = (labelId) => {
    setSelectedLabelId(labelId);
  };

  return (
    <StyledDrawer
      variant={isMobile ? "temporary" : "permanent"}
      anchor="left"
      open={isNavBarOpen}
      onClose={toggleNavBar}
      classes={{
        root: classes.drawer,
        paper: classes.drawerPaper,
      }}
      onMouseEnter={() => setNavBarOpen(true)}
      onMouseLeave={() => setNavBarOpen(false)}
    >
      <div className={classes.toolbar} />
        
      <List>
        <DrawerItem
          text={"Notes"}
          isSelected={selectedLabelId === ""}
          icon={<IdeaIcon htmlColor={theme.custom.palette.iconColor} />}
          onClick={selectAllLabels}
          />

        {labelItems.map((labelItem) => (
          <DrawerItem
            key={labelItem._id}
            text={labelItem.name}
            icon={<LabelIcon htmlColor={theme.custom.palette.iconColor} />}
            isSelected={selectedLabelId === labelItem._id}
            onClick={() => onDrawerItemSelected(labelItem._id)}
            />
        ))}
        
      <LabelsModal labels={labelItems} />
      </List>

    
    </StyledDrawer>
  );
}
