import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Search as SearchIcon, CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";
import { Box, InputBase, IconButton, Snackbar, ClickAwayListener } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useUiStore } from "../../store";

const useStyles = makeStyles()((theme) => {
  return {
    search: {
      display: "flex",
      justifyContent: "center",
      maxWidth: "100%",
      transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.shortest,
      }),
      marginLeft: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
    },
    iconDiv: {
      width: theme.spacing(7),
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      flex: 1,
      alignItems: "center",
    },
    inputInput: {
      width: "100%",
    },
  };
});

const SearchBar = ({ onSearchClose }) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const [isFocussed, setFocussed] = useState(false);
  // const [searchTerm, setSearchTerm] = useState("");
  const [isShowingToast, showToast] = useState(false);
  const [{ searchTerm }, { setSearchTerm }] = useUiStore();

  const onSearchCancel = () => {
    setSearchTerm("");
    setFocussed(false);
    onSearchClose();
  };
  const onSearch = (event) => {
    setFocussed(true);
    if (event.key === "Enter") {
      // console.log("SearchBar, event: ", event.target.value);
      showToast(true);
      setFocussed(false);
      onSearchClose();
    }
  };
  const onFocusLoss = () => {
    onSearchClose();
    setFocussed(false);
  };

  /*
  const handleToastClose = () => {
    showToast(false);
  };
  */

  return (
    <ClickAwayListener onClickAway={onFocusLoss}>
      <Box className={classes.search} bgcolor={isFocussed ? theme.palette.background.default : theme.palette.background.highlight} boxShadow={isFocussed ? 2 : 0} height={"3rem"}>
        <div className={classes.iconDiv}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={searchTerm}
          onClick={() => setFocussed(true)}
          inputProps={{ "aria-label": "search" }}
          onChange={(event) => setSearchTerm(event.target.value)}
          onKeyDown={onSearch}
        />
        <div className={classes.iconDiv}>
          {isFocussed ? (
            <IconButton hidden={!isFocussed} onClick={onSearchCancel}>
              <CloseOutlinedIcon htmlColor={theme.custom.palette.iconColor} />
            </IconButton>
          ) : null}
        </div>

        {/* <Snackbar open={isShowingToast} message={"Search not implemented ;)"} autoHideDuration={2000} onClose={handleToastClose} /> */}
      </Box>
    </ClickAwayListener>
  );
};

export default SearchBar;
