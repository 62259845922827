
const sanityClient = require("@sanity/client");

export const client = sanityClient({
  projectId: "2w8xtna6",
  dataset: "production",
  apiVersion: "2022-10-21", // use current UTC date - see "specifying API version"!
  //  token: "sanity-auth-token", // or leave blank for unauthenticated usage
  useCdn: false, // `false` if you want to ensure fresh data
  token: process.env.REACT_APP_SANITY_TOKEN,
  ignoreBrowserTokenWarning: true,
  resultSourceMap: true, // tells the API to start sending source maps, if available
});

