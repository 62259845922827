import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Typography,
  InputBase,
  Divider,
  Button,
  useTheme
} from "@mui/material";
import {
  CheckBoxOutlineBlankOutlined as CheckboxBlankIcon,
  CheckBoxOutlined as CheckboxIcon,
  AddOutlined as AddIcon,
  SearchOutlined as SearchIcon
} from "@mui/icons-material";
import { useLabelsStore, useUserStore } from "../../store";
import { useLabelsContext } from "../../context";
import { client } from "../../client";

const useStyles = makeStyles()((theme) => {
  //console.log("esto es de useStyles de LabelPopover: ", theme.spacing());
  return {
    popover: {
      width: theme.spacing(28),
      borderRadius: theme.spacing(0.5),
      background: theme.palette.background.default,
    },
    container: {
      display: "flex",
      flexDirection: "column",
    },
    header: {
      padding: theme.spacing(1.5, 1.5, 0, 1.5),
      marginBottom: theme.spacing(-1),
    },
    searchInput: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    inputText: {
      ...theme.custom.fontFamily.roboto,
      fontWeight: 400,
      fontSize: "0.8rem",
      flex: 1,
    },
    listItemIconRoot: {
      margin: "0 !important",
      padding: "0 !important",
      minWidth: "0px",
    },
    checkboxRoot: {
      margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(0.5)}px ${theme.spacing(1.5)}px !important`,
      padding: "0 !important",
    },
    listItemText: {
      ...theme.custom.fontFamily.roboto,
      fontWeight: 400,
      fontSize: "0.8rem",
    },
    footer: {
      display: "flex",
      flexDirection: "row",
      padding: theme.spacing(0.8, 1.5, 0.8, 1.5),
      borderRadius: 0,
      justifyContent: "left",
    },
    footerText: {
      ...theme.custom.fontFamily.roboto,
      fontWeight: 400,
      fontSize: "0.8rem",
      paddingLeft: theme.spacing(1),
      textTransform: "none",
      color: theme.palette.grey[900],
    },
  };
});

export default function LabelPopover({ anchorEl, labels, setLabels, isOpen, onClose }) {
  const { classes } = useStyles();
  const theme = useTheme();
  const popoverId = isOpen ? "color-popover" : undefined;
  // const [newLabelName, setNewLabelName] = useState("");
  const [allLabelItems, dispatchLabel] = useLabelsStore();
  const [newLabelTitle, setNewLabelTitle, onCreateLabel] = useLabelsContext();
  // const [chota, pis] = useLabelsContext();
  // console.log({newLabelTitle});
  const filteredLabelItems = allLabelItems.filter((labelItem) => newLabelTitle === "" || labelItem.name.includes(newLabelTitle));
  const [{ _id: userId }] = useUserStore();

  // console.log("LabelPopover, labels:", { labels });

  const updateNotesLabels = (labelItem) => {
    // console.log("LabelPopover, updateNotesLabels:", { labelItem });
    const index = labels.findIndex((label) => label._id === labelItem._id);
    // console.log("esto es index de updateLabelsForNote de LabelPopover:", index);
    if (index > -1) {
      labels.splice(index, 1);
    } else {
      //const labelToAdd = { _key: labelItem._id, _ref: labelItem._id, _type: "reference" };
      labels.push(labelItem);
    }
    setLabels(Object.assign([], labels));
    // console.log("LabelPopover, updateNotesLabels: ", {labels});
  };

  const onCreateLabelClick = async () => {
    onCreateLabel(userId)
    // .then(res => dispatchLabel({ type: "CREATED", payload: res }));
    // console.log(newLabel)
    /*
    const newLabel = {
      _type: "label",
      name: newLabelTitle,
      userId: {
        _type: "reference",
        _key: userId,
        _ref: userId,
        _weak: true,
      },
    };
*/
/*
    client.create(newLabel).then(
      (res) => {
        console.log("LabelPopover onCreateLabelClick res:", { res });
        dispatchLabel({ type: "CREATED", payload: res });
      },
      (err) => {
        console.log(err);
      }
    );
*/

  // dispatchLabel({type:"CREATED", payload:newLabel })
    // setNewLabelName("");
    // setNewLabelTitle("");
  };

  return (
    <div>
      <Popover
        id={popoverId}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{
          paper: classes.popover,
        }}
      >
        <div className={classes.container}>
          <div className={classes.header}>
            <Typography variant="subtitle2">Label note</Typography>
            <div className={classes.searchInput}>
              <InputBase
                classes={{ root: classes.inputText }}
                placeholder="Enter label name"
                value={newLabelTitle}
                onChange={(event) => setNewLabelTitle(event.target.value)}
                />
              <SearchIcon fontSize="small" htmlColor={theme.palette.grey[500]} />
            </div>
          </div>
          <List dense={true} component="div" style={{ width: "100%" }}>
            {filteredLabelItems?.map((labelItem) => {
              const labelAriaId = `checkbox-list-label-${labelItem._id}`;
              // console.log('LabelPopover, labelItem:', { labelItem });
              return (
                <ListItem
                  alignItems="flex-start"
                  key={labelItem._id}
                  dense={true}
                  button={true}
                  disableGutters={true}
                  classes={{ root: classes.listItemIconRoot }}
                  onClick={() => updateNotesLabels(labelItem)}
                  >
                  <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                    <Checkbox
                      tabIndex={-1}
                      icon={<CheckboxBlankIcon fontSize="small" />}
                      checkedIcon={<CheckboxIcon fontSize="small" />}
                      color="default"
                      disableRipple
                      checked={labels?.some((label) => label._id === labelItem._id)}
                      inputProps={{ "aria-labelledby": labelAriaId }}
                      size="small"
                      classes={{ root: classes.checkboxRoot }}
                      />
                  </ListItemIcon>
                  <ListItemText id={labelItem._id} disableTypography>
                    <Typography variant="body1" classes={{ root: classes.listItemText }}>
                      {labelItem.name}
                    </Typography>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
          {newLabelTitle !== "" ? (
            <>
              <Divider />
              <Button size="small" classes={{ root: classes.footer }} onClick={onCreateLabelClick}>
                <AddIcon fontSize="small" />
                <Typography classes={{ root: classes.footerText }}>
                  Create "<b>{newLabelTitle}</b>"
                </Typography>
              </Button>
            </>
          ) : null}
        </div>
      </Popover>
    </div>
  );
}
