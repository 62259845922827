/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from "react";
import { Container } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import jwt_decode from "jwt-decode";
import logo from "../assets/keepWalking.ico";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles()((theme) => {
  return {
    pageWrapper: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.highlight,
    },
    pageContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      flexGrow: "1",
    },
    boxWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
      padding: theme.spacing(3),
    },
    logo: {
      height: theme.spacing(10),
      margin: theme.spacing(0, 0, 3, 0),
    },
  };
});

export default function () {
  const { classes } = useStyles();
  const navigate = useNavigate();

  function handleCallbackResponse(res) {
    const credential = res.credential;
    const decoded = jwt_decode(credential);
    const user = JSON.stringify(decoded);

    if (user) {
      sessionStorage.setItem("user", user);
      return navigate("/");
    }
    return <></>;
  }

  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_IDENTITY_SERVICES_CLIENT_ID,
      callback: handleCallbackResponse,
    });

    window.google.accounts.id.renderButton(document.getElementById("googleBtn"), { theme: "outline", size: "large", shape: "circle" });

    window.google.accounts.id.prompt();
  });

  return (
    <>
      <div className={classes.pageWrapper}>
        <Container maxWidth="md" className={classes.pageContainer}>
          <img className={classes.logo} src={logo} alt={"logo"} />
          <div className={classes.boxWrapper}>
            <div id="googleBtn"></div>
          </div>
        </Container>
      </div>
    </>
  );
}
