/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { makeStyles } from "tss-react/mui";
import { InputBase, Typography } from "@mui/material";

const useStyles = makeStyles()((theme) => {
  return {
    inputTitleRoot: {
      fontWeight: 500,
      marginLeft: "2.rem",
      marginRight: "2.rem",
      marginBottom: "0.8em",
      padding: `${theme.spacing(1.5)}
                ${theme.spacing(2)}
                ${theme.spacing(1)}
                ${theme.spacing(2)}`,
    },
    inputTitleInput: {
      fontWeight: 600,
      fontSize: "1rem",
      padding: "0",
    },
    textTitle: {
      fontFamily: "Google Sans,Roboto,Arial,sans-serif",
      padding: `${theme.spacing(1.5)}
                ${theme.spacing(2)}
                ${theme.spacing(1)}
                ${theme.spacing(2)}`,
      fontWeight: 600,
      fontSize: "1rem",
      letterSpacing: ".00625em",
      color: theme.palette.text.primary,
      lineHeight: theme.spacing(2.8),
    },
    textTitleOnWorkloadView: {
      fontFamily: "Google Sans,Roboto,Arial,sans-serif",
      marginRight: "2.0rem",
      marginBottom: "0.8rem",
    },
  };
});

export default function ({ title, setTitle, isEditMode, onWorkloadMode, onWorkTrackingView, workload }) {
  const { classes } = useStyles();

  return (
    <>
      {isEditMode ? (
        <InputBase
          placeholder={"Title"}
          classes={{
            root: classes.inputTitleRoot,
            input: classes.inputTitleInput,
          }}
          inputProps={{ "aria-label": "note title" }}
          value={title}
          multiline={true}
          onChange={(event) => setTitle(event.target.value)}
        />
      ) : (
        <Typography
          component={"p"}
          className={
            onWorkTrackingView
              ? classes.textTitle
              : onWorkloadMode
              ? classes.textTitleOnWorkloadView // CHECK
              : classes.textTitle
          }
          variant="subtitle1"
        >
          {title}
        </Typography>
      )}
    </>
  );
}
