/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import TodoCreate from "./TodoCreate";
import { useMediaQuery } from "@mui/material";
import { useUiStore, useTodosStore, useUserStore, useWorkRecord } from "../../store";
import { client } from "../../client";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { TodosList } from "./TodosList";
import TodoItem from "./TodoItem";
import { removeItem } from "../../data";

const useStyles = makeStyles()((theme) => {
  //console.log(theme);
  return {
    content: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      //marginLeft: theme.mixins.drawer.minWidth - theme.spacing(2.5),
      marginLeft: theme.mixins.drawer.minWidth - theme.spacing(2.5).slice(0, -2),
      marginRight: -1 * theme.spacing(3),
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      //marginLeft: 0,
      //marginLeft: "50px", // SL
      marginLeft: `calc(${theme.spacing(7)} + 1px)`,
      [theme.breakpoints.up("sm")]: {
        marginLeft: `calc(${theme.spacing(8)} + 1px)`,
      },
    },
    todoCreateContainer: {
      display: "flex",
      padding: theme.spacing(4, 0),
      margin: theme.spacing(0, 1),
    },
    todoCreateWrapper: {
      flex: 1,
      maxWidth: theme.spacing(75),
      margin: "0 auto",
    },
  };
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function () {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.up("sm"));
  const isLaptop = useMediaQuery(theme.breakpoints.up("md"));
  const isLaptopL = useMediaQuery(theme.breakpoints.up("lg"));
  const is4K = useMediaQuery(theme.breakpoints.up("xl"));
  const [open, setOpen] = useState(false);

  const numberOfColumns = is4K ? 8 : isLaptopL 
                                ? 4 : isLaptop 
                                  ? 3 : isTablet 
                                    ? 2 : 1;
  let width = is4K ? "100%" : isLaptopL 
                    ? "1000px" : isLaptop 
                      ? "730px" : isTablet 
                        ? "480px" : "100%";

  const [{ isListView }] = useUserStore();
  const [{
    isNavBarOpen,
    noteInEditMode,
    selectedLabelId,
    onWorkTrackingView,
    searchTerm }] = useUiStore();
  const [storedTodos] = useTodosStore();
  const workRecords = useWorkRecord();

  width = isListView ? (isLaptop || isLaptopL ? theme.spacing(75) : "100%") : width;

  const filteredByLabel = storedTodos.filter((item) => {
    if (selectedLabelId !== "") {
      return item.labels.some((labelItem) => labelItem._id === selectedLabelId);
      // return true
    } else {
      return true;
    }
  });
  const filteredBySearchTerm = filteredByLabel.filter((item) => {
    if (searchTerm !== "") {
      return item.title.includes(searchTerm) || item.notes.some((note) => note.text.includes(searchTerm));
    } else {
      return true;
    }
  });
  const pinnedItems = filteredBySearchTerm.filter((item) => item.pinned);
  const otherItems = filteredBySearchTerm.filter((item) => !item.pinned);

  // console.log("NotesArea storedTodos: ", storedTodos);
  // console.log('NotesArea, se actualizo pinnedItems?', {pinnedItems})

  const deleteDocument = (itemType, defaultUser = "") => {
    const query = removeItem(itemType, defaultUser);
    console.log({ query });
    client
      .delete({ query: query })
      .then((res) => console.log("The " + itemType + "'s have been deleted", { res }))
      .catch((err) => {
        console.error("Oh no, I coudnt delete the " + itemType + "s", err);
      });
  };
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <main>
        <div className={isMobile || !isNavBarOpen ? classes.contentShift : classes.content}>
          <div className={classes.todoCreateContainer}>
            <div className={classes.todoCreateWrapper}>
              <TodoCreate onWorkTrackingView={onWorkTrackingView} />
            </div>
          </div>

          {onWorkTrackingView && 
            <TodosList 
              list={workRecords}
              title={!!workRecords?.length ? "Work tracking" : "No records yet"}
              isMobile={isMobile}
              isListView={isListView}
              numberOfColumns={numberOfColumns}
              width={width}
              noteInEditMode={noteInEditMode}
              />
            }
          {!onWorkTrackingView && 
            <TodosList
              list={pinnedItems}
              title={!!workRecords?.length ? "PINNED" : null}
              isMobile={isMobile}
              isListView={isListView}
              numberOfColumns={numberOfColumns}
              width={width}
              noteInEditMode={noteInEditMode} 
              />
            }
          {!onWorkTrackingView &&
            <TodosList
              list={otherItems}
              title={!!otherItems.length ? "OTHERS" : null}
              isMobile={isMobile}
              isListView={isListView}
              numberOfColumns={numberOfColumns}
              width={width}
              noteInEditMode={noteInEditMode}
              />
            }

          {/* snackbar */}
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
              This is a success message!
            </Alert>
          </Snackbar>

          {/* buttons */}
          <div>
            <section>
              <button onClick={handleClick}>Open success snackbar</button>
              <button onClick={() => deleteDocument("todo")}>BORRAR TODOS</button>
              <button onClick={() => deleteDocument("label")}>BORRAR LABELS</button>
              <button onClick={() => deleteDocument("user")}>BORRAR USERS</button>
              <button onClick={() => deleteDocument("workloadTracking")}>BORRAR WORKLOAD</button>
            </section>
          </div>
          {/*
           */}
        </div>
      </main>
    </>
  );
}
