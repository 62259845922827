/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import AppBar from "./appbar/AppBar";
import NavDrawer from "./navdrawer/NavDrawer";
import NotesArea from "./mainarea/NotesArea";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Loading from "./Loading";
import { client } from "../client";
import { getInitialData as createQuery } from "../data";
import { 
  TodosProvider,
  LabelsProvider,
  UiProvider,
  UserProvider,
  WorkRecorsProvider,
  useUserStore,
  useLabelsStore,
  } from "../store";
import { LabelsContextProvider } from "../context";
import { CssBaseline } from "@mui/material";
import { dark, light } from "../theme";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

export default function () {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const navigate = useNavigate();

  let sessionUser = sessionStorage.getItem("user");

  async function fetchInitialData(id) {
    const query = createQuery(id);
    return await client
      .fetch(query)
      .then((res) => {
        // console.log("This is Main, fetchInitialData, res: ", res);
        setData(res);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    if (sessionUser === null) {
      navigate("/login");
    } else {
      const { sub: _id, family_name, given_name, picture, email } = JSON.parse(sessionUser);
      const googleUserData = { _id, family_name, given_name, picture, email };

      function commitUser(updatedUser) {
        client
          .createOrReplace(updatedUser)
          .then((res) => fetchInitialData(res._id))
          .catch((err) => console.error(err));
      }

      client
        .fetch(`*[_id=="${_id}"][0]`)
        .then((dbUser) => {
          // if already a user, updates google data
          const updatedUser = dbUser 
                ? { ...dbUser, ...googleUserData } 
                : { _type: "user", darkMode: false, listMode: false, ...googleUserData };

          commitUser(updatedUser);
        })
        .catch((err) => console.log("Oh no, we've got an error", err));
    }
  }, [navigate, sessionUser]);

  if (loading) {
    return <Loading />;
  }


  return <MainComponent
          todos={data.todos}
          labels={data.labels}
          user={data.user}
          workRecords={data.workRecords}
          />
}

function MainComponent({ todos, labels, user, workRecords }) {
  return (
    <>
      <TodosProvider todos={todos}>
        <LabelsProvider labels={labels}>
          <UserProvider user={user}>
            <WorkRecorsProvider records={workRecords}>
              <UiProvider>
                <LabelsContextProvider>
                  <ThemeControlledComponent />
                </LabelsContextProvider>
              </UiProvider>
            </WorkRecorsProvider>
          </UserProvider>
        </LabelsProvider>
      </TodosProvider>
    </>
  );
}

function ThemeControlledComponent() {
  const [{ isDarkMode }] = useUserStore();

  return (
    <ThemeProvider theme={isDarkMode ? dark : light}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <AppBar />
      <NavDrawer />
      <Container maxWidth={false}>
        <Box mt={8}>
          <NotesArea />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
