/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { makeStyles } from "tss-react/mui";
import { ListItem } from "@mui/material";
import { ListItemIcon, ListItemText } from "@mui/material";

const useStyles = makeStyles()((theme) => {
  return {
    listItemRoot: {
      borderRadius: theme.spacing(0, 3, 3, 0),
      padding: theme.spacing(".3rem", "1rem", ".3rem", "1rem"),
    },
    listItemSelected: {
      backgroundColor: `${theme.palette.secondary.light} !important`,
    },
  };
});

export default function ({ text, icon, isSelected, onClick }) {
  const { classes } = useStyles();
  return (
    <ListItem
      button
      disableRipple
      // disableTouchRipple
      // disableFocusRibble
      selected={isSelected}
      classes={{
        selected: classes.listItemSelected,
        root: classes.listItemRoot,
      }}
      onClick={onClick}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
}
