import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Popover, Typography, Avatar, Divider, Button, useTheme } from "@mui/material";
import { useUserStore, useUiStore, useTodosStore } from "../../store";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Stack from "@mui/material/Stack";
import { client } from "../../client";

const useStyles = makeStyles()((theme) => {
  //console.log({ theme });
  return {
    popover: {
      background: theme.custom.palette.profilePopColor,
      width: theme.spacing(40),
    },
    container: {
      display: "flex",
      padding: theme.spacing(2),
      flexDirection: "column",
      alignItems: "center",
    },
    bar: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "row-reverse",
      alignItems: "center",
    },
    button: {
      ...theme.custom.fontFamily.metropolis,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.5, 2),
      fontSize: "0.9rem",
      fontWeight: 600,
      textTransform: "none",
      color: theme.palette.grey[800],
      marginLeft: "0.5rem",
    },
  };
});

export default function WorkloadPopover({ anchorEl, isOpen, onClose }) {
  const theme = useTheme();
  const { classes } = useStyles(theme);
  const [date, setDate] = useState(new dayjs());
  const [{ onWorkloadMode }, { setOnWorkloadMode }] = useUiStore();
  const [, dispatchTodo] = useTodosStore();

  // const id = isOpen ? "workload-popover" : undefined;

  function handleSwitch() {
    setOnWorkloadMode(!onWorkloadMode);
  }

  const handleDatePickerChange = (newDate) => {
    setDate(newDate);
  };

  const handleSaveWorkload = () => {
    const query = '*[ _type == "todo" && workload != 0 ]';

    client.fetch(query).then((todos) => {
      todos.forEach((todo) => {
        const doneWork = {
          _type: "workloadTracking",
          date: date.format("YYYY-MM-DD"),
          labels: todo.labels,
          title: todo.title,
          notes: todo.notes,
          workload: todo.workload,
          pinned: todo.pinned,
          isCheckBoxMode: todo.isCheckBoxMode,
          color: todo.color,
        };

        client.create(doneWork).then(
          client
            .patch(todo._id)
            .set({ ...todo, workload: 0 })
            .commit()
            .then((todo) => {
              const q = `*[_type=="todo" && _id == "${todo._id}"]{..., labels[]->{...}}`;
              // console.log({ q });
              client.fetch(q).then((res) => {
                // console.log("esto es el response de hanleSaveWorkload: ", res);
                dispatchTodo({ type: "UPDATED", payload: res[0] });
              });
            })
        );
      });
    });
    setOnWorkloadMode(false);
  };

  const handleReset = () => {
    console.log("reset!");
  };

  //console.log(date.format("YYYY-MM-DD"));

  // ENVIAR EL WEBHOOK POR CADA WORKLOAD GENERADO

  return (
    <div>
      <Popover
        /* id={id} */
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{
          paper: classes.popover,
        }}
      >
        <div className={classes.container}>
          <Stack spacing={3} sx={{ my: 3 }}>
            <FormControlLabel onChange={handleSwitch} control={<Switch checked={onWorkloadMode} color="default" />} label="Workload mode" sx={{ ml: 1 }} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker label="Date desktop" inputFormat="DD/MM/YYYY" value={date} onChange={handleDatePickerChange} renderInput={(params) => <TextField {...params} />} />
            </LocalizationProvider>
          </Stack>
        </div>
        <Divider />
        <Stack className={classes.bar}>
          <Button className={classes.button} onClick={handleSaveWorkload} variant="outlined">
            Save
          </Button>
          <Button className={classes.button} onClick={handleReset}>
            Reset
          </Button>
        </Stack>
      </Popover>
    </div>
  );
}
