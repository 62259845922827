import React, { useState, useRef, useEffect } from "react";
import { 
  CircularProgress,
  useTheme,
  IconButton,
  List,
  Divider,
  InputBase,
  TextField,
  Box,
  Typography,
  Modal as MuiModal,
  Button,
  Stack 
  } from "@mui/material";
import { 
  Done as DoneIcon,
  Close as CloseIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Label as LabelIcon,
  Delete as DeleteIcon,
  Troubleshoot 
  } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { client } from "../../client";
import { useLabelsStore, useTodosStore } from "../../store";
import DrawerItem from "./DrawerItem";
import { getInitialData, getTodos, getLabels, getTodosAndLabels } from "../../data";
import { useUserStore } from "../../store";
import { useLabelsContext } from "../../context";

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

const LabelItem = ({ label, setNewLabelActive }) => {
  const [activeRow, setActiveRow] = useState(false);
  const [beenEdited, setBeenEdited] = useState(false);
  const [, dispatchTodo] = useTodosStore();
  const [labelText, setLabelText] = useState(label.name);
  const [newLabelName, setNewLabelName] = useState("");
  const [, dispatchLabel] = useLabelsStore();
  // console.log("LabelsModal, label: ", label)

  const deleteLabel = () => {
    const { _id } = label;
    client.delete(_id).catch((err) => {
      console.error("Oh no, the delete failed", err);
    });
    dispatchLabel({ type: "DELETED", payload: label });
  };
  const editLabel = (_id) => {
    //console.log("se activo editLabel");
    client
      .patch(_id)
      .set({ name: newLabelName })
      .commit()
      .then((newLabel) => {
        //console.log("new label de editLabel de LabelsModal:", newLabel);
        updateTodos(_id);
      })
      .catch((err) => {
        console.error("Oh no, the update failed", err.message);
      });
    dispatchLabel({ type: "UPDATED", payload: { _id, name: newLabelName } });
    setBeenEdited(false);
  };
  const updateTodos = (id) => {
    // console.log({ id });
    const q = `*[_type =='todo' && "${id}" in labels[]._ref ]{ ..., labels[]->{...}}`;
    client.fetch(q).then((todos) => {
      todos.forEach((todo) => {
        //console.log({ todo });
        dispatchTodo({ type: "UPDATED", payload: todo });
      });
      // console.log("esto son los todos de updateTodos en LabelsModal: ", todos);
      //console.log("esto es todos de updateTodos en LabelsModal: ", todos);
    });
  };
  const handleFocus = () => {
    //console.log("focus!");
    setBeenEdited(true);
    setNewLabelActive(false);
  };
  const handleBlur = () => {
    // console.log("blur!");
    setBeenEdited(false);
  };
  const handleLeftButton = () => {
    if (activeRow) {
      deleteLabel();
    }
  };
  const handleRightButton = (label) => {
    //console.log("click en handleRightButton!");
    if (activeRow) {
      //console.log("if activeRow ok!");
      // if (beenEdited) {
      //console.log("if beenEdited ok!");
      editLabel(label._id);
      //}
    }
  };

  return (
    <>
      <Stack
        onMouseEnter={() => setActiveRow(true)}
        onMouseLeave={() => setActiveRow(false)}
        direction={"row"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          onClick={handleLeftButton}>
            {activeRow 
              ? <DeleteIcon style={{ fontSize: "1.2rem" }} color="action" />
              : <LabelIcon style={{ fontSize: "1.2rem" }} color="action" />
            }
        </IconButton>
        <InputBase
          style={{
            fontSize: "1rem",
          }}
          value={labelText}
          onChange={(e) => setLabelText(e.target.value)}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <IconButton
          onClick={() => handleRightButton(label)}
          >
          {beenEdited 
            ? <DoneIcon style={{ fontSize: "1.2rem" }} /> 
            : <EditIcon style={{ fontSize: "1.2rem" }} />
          }
        </IconButton>
      </Stack>
    </>
  );
};

export const LabelsModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [newLabelActive, setNewLabelActive] = useState(true);
  const [newLabelName, setNewLabelName] = useState("");
  const [allLabelItems,] = useLabelsStore();
  const [{ _id: userId }] = useUserStore();
  const [newLabelTitle, setNewLabelTitle, onLabelCreate] = useLabelsContext();
  const theme = useTheme();
  const inputRef = useRef(null);

  // console.log("LabelsModal, newLabelActive: ", newLabelActive);

  const handleEnter = (e) => {
    if (e.key === "Enter" && newLabelName.length > 0) {
      onCreateLabel();
    }
  };
  const handleFocus = () => {
    setNewLabelActive(true);
  };
  const handleBlur = () => {
    setNewLabelActive(false);
  };
  const handleResetButton = () => {
    if (newLabelName.length > 0) {
      setNewLabelName("");
      //setNewLabelActive(false);
    } else {
      inputRef.current.focus();
    }
  };
  const handleOkButton = () => {
    //console.log("ok button");
    if (newLabelName.length > 0) {
      onCreateLabel();
      inputRef.current.focus();
    }
  };
  const onCreateLabel = async () => {
    setNewLabelActive(false);
    setNewLabelTitle("");
    onLabelCreate()
    setNewLabelActive(true);
  };

  return (
    <>
      <DrawerItem
        onClick={() => setShowModal(true)}
        text="Edit labels"
        icon={<EditIcon htmlColor={theme.custom.palette.iconColor} />} 
        />
      <MuiModal
        disableRestoreFocus
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
        >
        <Box sx={boxStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
            >
            Edit tags
          </Typography>
          <List>
            {
              <>
                <Stack
                  direction={"row"}
                  sx={{ 
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between" 
                    }}
                  >
                  <IconButton onClick={handleResetButton}>
                    {newLabelActive 
                      ? <CloseIcon style={{ fontSize: "1.2rem" }} color="action" />
                      : <AddIcon style={{ fontSize: "1.2rem" }} color="action" />
                    }
                  </IconButton>
                  <InputBase
                    // onClick={handleFocus}
                    onFocus={handleFocus}
                    onKeyDown={(e) => handleEnter(e)}
                    disabled={!newLabelActive}
                    value={newLabelTitle}
                    inputRef={inputRef}
                    autoFocus
                    placeholder="Create a new label"
                    onChange={(e) => setNewLabelTitle(e.target.value)}
                  />
                  {(newLabelActive || newLabelName.length) > 0 && (
                    <IconButton onClick={handleOkButton}>
                      <DoneIcon style={{ fontSize: "1.2rem" }} />
                    </IconButton>
                  )}
                </Stack>

                {allLabelItems?.map((label) => (
                  <LabelItem
                    label={label}
                    key={label._id}
                    setNewLabelActive={setNewLabelActive}
                  />
                ))}
              </>
            }
          </List>
          <Divider />
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              onClick={() => setShowModal(false)}
              sx={{
                color: theme.custom.palette.iconColor,
                paddingY: ".5rem",
                marginTop: "1rem",
                textTransform: "capitalize",
                fontWeight: 600,
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
      </MuiModal>
    </>
  );
};
