/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

export default function () {
  return (
    <Backdrop open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
