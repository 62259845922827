import React, { createContext, useContext, useState, } from "react";
import { client } from "./client";
import { useLabelsStore } from "./store";

// this component shouldn't exist...but

const LabelsContext = createContext([]);

export function LabelsContextProvider({ children}) {
  const [newLableTitle, setNewLableTitle] = useState("");
  const [, dispatchLabel] = useLabelsStore()

  const onCreateLabel = async (userId) => {
      const newLabel = {
        _type: "label",
        name: newLableTitle,
        userId: {
          _type: "reference",
          _ref: userId,
          _weak: true,
        },
      };
      client
        .create(newLabel)
        .then((res) => {dispatchLabel({ type: "CREATED", payload: res })},
              (err) => {console.log(err);});
      setNewLableTitle("");
    };

  return <LabelsContext.Provider value={[newLableTitle, setNewLableTitle, onCreateLabel]}>
                {children}
         </LabelsContext.Provider>;
}


export const useLabelsContext = () => useContext(LabelsContext);